import Vue from 'vue';
import i18next from 'i18next';
import VueI18Next from '@panter/vue-i18next';
import LanguagesLCID from '@utils/lcid-languages.json';
import { getCurrentRegion } from '@utils/helper-region';
import { COUNTRIES } from '@src/enums/regions';
import { messages } from './locales';

i18next
  .init({
    debug: false,
    defaultNS: 'translations',
    ns: ['translations'],
    fallbackLng: LanguagesLCID[COUNTRIES.BR],
    lng: getCurrentRegion().lcid,
    resources: messages,
  })

Vue.use(VueI18Next);

export {
  i18next,
};

export default new VueI18Next(i18next);
