export default {
  title: 'Nota de dirección',
  driverScore: {
    improve: 'Mal',
    good: 'Bueno',
    excellent: 'Excelente',
    regular: 'Regular',
    notfound: 'No disponible',
  },
  label: {
    score: 'Nota de dirección',
    break: 'Frenado',
    turn: 'Curvas',
    throttle: 'Aceleración',
    overspeeding: 'Velocidad',
  },
  helpText: {
    score: {
      improve: 'Este conductor maneja peor que casi todos los demas, debe mejorar. Recuerdale que tener una mala calificación puede causar que le pidamos el Kovi de regreso.',
      regular: 'Este conductor maneja peor que el promedio, podria mejorar. Recuerdale leer el Manual del Buen Conductor.',
      good: 'Este conductor maneja bien, pero no es de los mejores. Recuerdale leer el Manual del Buen Conductor.',
      excellent: 'Este conductor maneja de manera espectacular, es de los mejores en México.',
      notfound: 'No disponible',
    },
    break: {
      improve: 'Las frenadas bruscas crean una gran sensación de incomodidad en los pasajeros, además de estar más expuestos a accidentes. Es importante que el conductor también evite frenar demasiado cerca de otros vehículos.',
      regular: '¡Al conductor le va bien en este tema!',
      good: 'Este conductor maneja bien, pero no es de los mejores. Recuerdale leer el Manual del Buen Conductor',
      excellent: '¡La conducción del conductor es excelente en este artículo!',
      notfound: 'No disponible',
    },
    turn: {
      improve: 'A nadie le gusta que le den vueltas en el coche, los pasajeros generalmente dan una mala calificación para este tipo de conducción. También es importante que el conductor tenga cuidado con la pista mojada. :)',
      regular: '¡Al conductor le va bien en este tema!',
      good: 'Este conductor maneja bien, pero no es de los mejores. Recuerdale leer el Manual del Buen Conductor',
      excellent: '¡La conducción del conductor es excelente en este artículo!',
      notfound: 'No disponible',
    },
    throttle: {
      improve: 'Las aceleraciones bruscas hacen que el conductor gaste más combustible del necesario, además de dejarlo más expuesto a accidentes.',
      regular: '¡Al conductor le va bien en este tema!',
      good: 'Este conductor maneja bien, pero no es de los mejores. Recuerdale leer el Manual del Buen Conductor',
      excellent: '¡La conducción del conductor es excelente en este artículo!',
      notfound: 'No disponible',
    },
    overspeeding: {
      improve: 'La velocidad excesiva puede hacer que el conductor gane multas de tráfico no deseadas, además de dejarlo más expuesto a accidentes si la condición del tráfico cambia repentinamente (por ejemplo, colas). Respetar el límite de velocidad de la carretera es super importante en este caso.',
      regular: '¡Al conductor le va bien en este tema!',
      good: 'Este conductor maneja bien, pero no es de los mejores. Recuerdale leer el Manual del Buen Conductor',
      excellent: '¡La conducción del conductor es excelente en este artículo!',
      notfound: 'No disponible',
    },
  },
}
