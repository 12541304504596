export default {
  document: 'CURP',
  phone: 'Teléfono',
  isActive: 'Activo',
  selectLabel: 'Presione \'enter\' para seleccionar',
  isActiveBooking: 'Reserva activa',
  noResult: 'No se pudo encontrar con sus términos de búsqueda.',
  lastSearch: 'Últimas búsquedas visitadas',
  placeholder: "Buscar por Nombre, correo electrónico, CURP o teléfono",
  categoryOptions: {
    cars: 'Vehículos',
    drivers: 'Conductores',
  },
  error: {
    ops: 'Ocurrio un error!',
  },
  warning: {
    hey: 'Hey Mira!',
    sameRoute: 'Estás intentando acceder a una búsqueda en la que ya estás en el resultado.'
  },
}
